<template>
  <CRow>
    <CCol>
      <PDFJSViewer :path="`${pdfPath}`" :fileName="`${pdfFileName}`"/> 
    </CCol>
  </CRow>
</template>

<script>
import PDFJSViewer from '@/components/pdf/PDFJSViewer'
import { mapGetters } from "vuex";
export default {
  name: "PdfViewerModal",
  components: {
    PDFJSViewer
  }, 
  data() {
    return {
      pdfPath: '/lib/pdfjs/web/viewer.html'
    }
  },
   computed: {
    ...mapGetters("Report", [
      "pdfFileName",     
    ]),
  },
};
</script>

<style scoped lang="scss">
</style>
