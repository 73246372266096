<template>
  <div>
    <CRow class="pb-3">
      <CCol> <h4 class="text-uppercase">Ledger Billing Report</h4> </CCol>
    </CRow>
    <CCard>
      <form v-on:submit.prevent="filterBill(search)">
        <CRow class="p-3">
          <CCol cols="2">
            <div class="form-group">
              <input
                type="text"
                name="account_number"
                placeholder="A/C No"
                class="form-control"
                v-model="search.account_number"
                required
              />
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select
                name="from-year"
                v-model="search.fromYear"
                class="form-control"
                required
              >
                <option value>Select Bill Year From</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </CCol>

          <CCol cols="2">
            <div class="form-group">
              <select
                name="to-year"
                v-model="search.toYear"
                class="form-control"
                required
              >
                <option value>Select Bill Year To</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </CCol>

          <CCol cols="1">
            <button type="submit" class="btn btn-success w-100">Search</button>
          </CCol>
          <CCol
            cols="2"
            v-if="bills.length"
            class="text-right"
            title="Download Report"
            v-cloak
          >
            <a href="javascript:void(0)" @click="downloadReport()">
              <CIcon class="mb-2" name="cil-cloud-download" height="30" />
            </a>
            &nbsp;
            <a
              href="javascript:void(0)"
              @click="downloadReport(true)"
              title="Preview Report"
            >
              &nbsp; &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path fill="black"
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                />
                <path  fill="black"
                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                />
              </svg>
            </a>
            &nbsp;
            <a
              @click="exportBill()"
              href="javascript:void(0)"
              title="Export to Excel"
            >
              <svg
                enable-background="new 0 0 30 30"
                height="30px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 30 30"
                width="30px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g>
                  <path
                    clip-rule="evenodd"
                    d="M28.705,7.506l-5.461-6.333l-1.08-1.254H9.262   c-1.732,0-3.133,1.403-3.133,3.136V7.04h1.942L8.07,3.818c0.002-0.975,0.786-1.764,1.758-1.764l11.034-0.01v5.228   c0.002,1.947,1.575,3.523,3.524,3.523h3.819l-0.188,15.081c-0.003,0.97-0.79,1.753-1.759,1.761l-16.57-0.008   c-0.887,0-1.601-0.87-1.605-1.942v-1.277H6.138v1.904c0,1.912,1.282,3.468,2.856,3.468l17.831-0.004   c1.732,0,3.137-1.41,3.137-3.139V8.966L28.705,7.506"
                    fill="green"
                    fill-rule="evenodd"
                  />
                  <path
                    d="M20.223,25.382H0V6.068h20.223V25.382 M1.943,23.438h16.333V8.012H1.943"
                    fill="#08743B"
                  />
                  <polyline
                    fill="#08743B"
                    points="15.73,20.822 12.325,20.822 10.001,17.538 7.561,20.822 4.14,20.822 8.384,15.486 4.957,10.817    8.412,10.817 10.016,13.355 11.726,10.817 15.242,10.817 11.649,15.486 15.73,20.822  "
                  />
                </g>
              </svg>
            </a>
          </CCol>
        </CRow>
      </form>

      <CRow>
        <CCol class="partner-custom-table-design">
          <div class="table-responsive p-3">
            <table class="table table-borderless" v-if="bills.length">
              <thead>
                <tr>
                  <th class="font-weight-bold">Consumer Name</th>
                  <th class="font-weight-bold">Bill Number</th>
                  <th class="font-weight-bold">Meter Serial No</th>
                  <th class="font-weight-bold">Used Unit</th>
                  <th class="font-weight-bold">Total Amount</th>
                  <th class="font-weight-bold">Status</th>
                  <th class="fon-weight-bold">Billing Month</th>
                  <th class="font-weight-bold">Issued At</th>
                  <th class="font-weight-bold">Action</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="bill in bills" :key="bill.id">
                  <td>
                    <router-link
                      class="text-info"
                      :to="'/customer-accounts/' + bill.customer_account_id"
                      >{{ bill.customer_name }}</router-link
                    >
                  </td>
                  <td>{{ bill.bill_number }}</td>
                  <td>{{ bill.meter_serial_no }}</td>
                  <td>{{ bill.used_unit }}</td>
                  <td>{{ bill.total_amount }}</td>
                  <td>{{ bill.status }}</td>
                  <td>{{ months[bill.bill_month] }}, {{ bill.bill_year }}</td>
                  <td>{{ bill.issue_date | moment }}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      class="download-bill btn btn-primary btn-sm"
                      @click="downloadBill(bill)"
                      >Download</a
                    >
                    &nbsp;
                    <br />
                    <!-- <router-link :to="'/bills/'+bill.id+'/consumptions'" class="btn btn-info btn-sm">View</router-link> &nbsp;                   -->
                  </td>
                </tr>
              </tbody>

              <!-- <tbody v-else>
              <tr>
                <td colspan="10">
                  <h5 class="text-center">Billing Not Available.</h5>
                </td>
              </tr>
            </tbody> -->
            </table>
            <table
              v-if="bills.length <= 0 && status == 1"
              class="table table-borderless"
            >
              <thead>
                <tr>
                  <th class="font-weight-bold">Customer Name</th>
                  <th class="font-weight-bold">Bill Number</th>
                  <th class="font-weight-bold">Meter Serial No</th>
                  <th class="font-weight-bold">Used Unit</th>
                  <th class="font-weight-bold">Total Amount</th>
                  <th class="font-weight-bold">Status</th>
                  <th class="fon-weight-bold">Billing Month</th>
                  <th class="font-weight-bold">Issued At</th>
                  <th class="font-weight-bold">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="9">
                    <h5 class="text-center">Billing Not Available.</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>

      <CPagination
        v-if="bills.length"
        align="center"
        :pages="rows"
        :active-page.sync="search.currentPage"
        @update:activePage="handlePageChange"
      ></CPagination>

      <CModal
        size="xl"
        title="Report Preview"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="viewPDF"
      >
        <PdfViewerModal></PdfViewerModal>
      </CModal>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import PdfViewerModal from "./PdfViewerModal";
export default {
  name: "Bills",
  data: () => {
    return {
      viewPDF: false,
      search: {
        account_number: "",
        fromYear: "",
        toYear: "",
        currentPage: 1,
      },
      // customer: { first_name: "", last_name: "" },
      dateFormat: "yyyy-MM-dd",
      isMobile: Boolean,
      isMobileOnly: Boolean,
      // bills: [],
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      // isLoading: false,
      // rows: 1,
      // perPage: 10,
      // currentPage: 1,
      // status: "",
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false}, {text:"Ledger-Billing", path:"", isActive: true}]
    };
  },
  components: {
    PdfViewerModal,
  },
  computed: {
    ...mapGetters("Report", ["bills", "rows", "perPage", "status", "customer"]),
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD MMM, YYYY");
    },
  },
  methods: {
    fromCustomFormatter(date) {
      return moment(date).format("Y-MM-D");
    },
    toCustomFormatter(date) {
      return moment(date).format("Y-MM-DD");
    },
    // getbills() {
    //   this.isLoading = true;
    //   var baseUrl = `/admin/reports/ledger-billing?page=${this.currentPage}&fromYear=${this.search.fromYear}&toYear=${this.search.toYear}&account_number=${this.search.account_number}`;
    //   try {
    //     this.axios
    //       .get(baseUrl)
    //       .then((res) => {
    //         this.bills = res.data.data;

    //         if (this.bills.length) {
    //           var customerId = this.bills[0].customer_id;
    //           this.getCustomer(customerId);
    //         }

    //         this.perPage = parseInt(res.data.meta.per_page);
    //         this.rows =
    //           parseInt(res.data.meta.per_page) *
    //           parseInt(res.data.meta.last_page);
    //         this.status = "1";
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       });
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },
    // getCustomer(customerId) {
    //   this.isLoading = true;
    //   try {
    //     this.axios
    //       .get(`/admin/customers/${customerId}`)
    //       .then((res) => {
    //         this.customer = res.data.data;
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       });
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },
    downloadReport(isView) {
      isView = isView ? true : false;
      this.$store.state.Report.isLoading = true;
      // this.$store.dispatch("Report/downloadLedgerBillRport",search)
      if (this.bills.length) {
        var customerId = this.bills[0].customer_account_id;
        //   this.getCustomer(customerId);
      }
      this.axios
        .get(
          `/admin/ledger-reports/download?id=${customerId}&fromYear=${this.search.fromYear}&toYear=${this.search.toYear}&account_number=${this.search.account_number}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          this.$store.state.Report.isLoading = false;
          let blob = new Blob([res.data], {
            type: "application/pdf;charset=utf-8",
          });

          if (isView) {
            var url = URL.createObjectURL(blob);
            this.$store.state.Report.pdfFileName = encodeURIComponent(url);
            this.viewPDF = isView;
            this.viewPdf();
          } else {
            var number = Math.ceil(Math.random() * 1000);
            var filename = number + "_bill.pdf";
            saveAs(blob, filename);
          }
        })
        .catch(() => {
          this.$store.state.Report.isLoading = false;
          // this.$toastr("error", "Something went wrong", "Error!");
        });
    },
    downloadBill(bill) {
      this.$store.dispatch("Report/downloadBill", bill);
    },
    exportBill() {
      this.$store.state.Report.isLoading = true;
      this.axios
        .get(
          `/admin/bills/report/export?account_number=${this.search.account_number}&fromYear=${this.search.fromYear}&toYear=${this.search.toYear}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          this.$store.state.Report.isLoading = false;
          let blob = new Blob([res.data], {
            type: "application/xlsx;charset=utf-8",
          });
          var number = Math.ceil(Math.random() * 1000);
          var filename = number + "_bills.xlsx";
          saveAs(blob, filename);
        })
        .catch(() => {
          this.$store.state.Report.isLoading = false;
          this.$toastr("error", "Something went wrong", "Error!");
        });
    },

    filterBill(search) {
      if (search) {
        this.$store.dispatch("Report/getLedgerBills", search);
      }
    },

    handlePageChange(value) {
      this.search.currentPage = value;
      this.$store.dispatch("Report/getLedgerBills", this.search);
    },

    viewPdf() {
      this.$refs["pdf-viewer-modal"].show();
    },
  },
  mounted() {
    // this.getbills();
    // this.searchBilling()
  },
  created() {
    this.$store.dispatch("Report/resetBills");
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>
<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> button {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
