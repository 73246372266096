import { render, staticRenderFns } from "./PdfViewerModal.vue?vue&type=template&id=355aa2ea&scoped=true&"
import script from "./PdfViewerModal.vue?vue&type=script&lang=js&"
export * from "./PdfViewerModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355aa2ea",
  null
  
)

export default component.exports